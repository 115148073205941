import { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import { CartContext } from "../../../utils/CartContext";
import { Product } from "../../../utils/types";
import "./ProductDetail.css";
import { formatPrice } from "../../../common/components/layouts/defaultLayout/DefaultLayout";

const getImageSrc = (image: { imageData?: string; imageUrl?: string }): string => {
  if (image.imageData && !image.imageData.startsWith("/images")) {
    return `data:image/jpeg;base64,${image.imageData}`;
  }
  if (image.imageUrl && image.imageUrl.startsWith("/images")) {
    return `https://arcview.nodiatech.de${image.imageUrl}`;
  }
  if (image.imageData && image.imageData.startsWith("/images")) {
    return `https://arcview.nodiatech.de${image.imageData}`;
  }
  return "";
};

const ProductDetail = () => {
  const { articleId } = useParams();
  const { addToDatabaseCart, addToCart } = useContext(CartContext);
  const [product, setProduct] = useState<Product | null>(null);
  const [manufacturerInfo, setManufacturerInfo] = useState<string | null>(null);
  const [modalImage, setModalImage] = useState<string | null>(null);
  const [, setCurrentIndex] = useState(0);
  const sliderRef = useRef<any>(null);
  const loggedInUser = localStorage.getItem("loggedInUser");

  const fetchProduct = async () => {
    try {
      const response = await fetch(`/api/aeb/products/${articleId}`);
      if (!response.ok) {
        throw new Error(`Fehler beim Laden des Produkts: ${response.statusText}`);
      }
      const data = await response.json();
      setProduct(data.product);
      setManufacturerInfo(data.manufacturerInfo);
    } catch (error) {
      console.error("Fehler beim Laden des Produkts:", error);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, [articleId]);

  const filteredImages =
    product?.images?.[0]?.imageData && !product.images[0].imageData.startsWith("/images")
      ? product.images.filter((_, index) => index % 3 === 0)
      : product?.images || [];

  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    centerPadding: "0",
    centerMode: true,
    afterChange: (currentIndex: number) => setCurrentIndex(currentIndex),
    customPaging: function (i: number) {
      return (
        <span className="h-12 w-12 xl:h-20 xl:w-20">
          <img
            src={getImageSrc(filteredImages[i] || {})}
            className="h-12 w-12 xl:h-20 xl:w-20 object-cover"
            alt={`thumbnail-${product?.productName}`}
          />
        </span>
      );
    },
  };

  const renderArticles = () => {
    if (!product) return;
    const articles = [
      { title: "WERKART", content: product.clockworkType },
      { title: "MATERIAL-KÖRPER", content: product.bodyMaterialBezel },
      { title: "ARMBAND", content: product.wristband },
      { title: "ARMBAND-FARBE", content: product.wristbandColor },
      { title: "ARMBAND-MATERIAL", content: product.wristbandMaterial },
      { title: "SCHLIEßE", content: product.wristbandClosure },
      { title: "MATERIAL-SCHLIEßE", content: product.wristbandClosureMaterial },
      { title: "ZIFFERBLATT-FARBE", content: product.clockFaceColor },
      { title: "ZIFFERBLATT-TYP", content: product.clockFaceType },
      { title: "FINISH", content: product.finish },
      { title: "ABMESSUNGEN", content: product.height ? `${product.height} cm` : undefined },
      { title: "DURCHMESSER", content: product.diameter ? `${product.diameter} cm` : undefined },
      { title: "WASSERDICHTE", content: product.waterResistance },
    ];

    return (
      <div className="">
        <hr className="max-w-1/2 mx-auto mb-8 h-[1px] w-full bg-gray-300" />
        <div className="xl:max-w-1/2 mx-auto flex w-full flex-col items-center justify-center gap-12 md:max-w-[80%] lg:flex-row lg:items-start lg:justify-center lg:gap-0">
          <div className="flex h-full w-full flex-col items-start justify-between lg:w-1/2">
            <div className=" flex flex-col items-center justify-start">
              <h1 className="w-3/4 text-left text-2xl">Details</h1>
              <p className="font-Geist mt-6 w-3/4 text-base">{product.additionalInfo}</p>
              <br />
              <p className="font-Geist mt-6 w-3/4 text-base">
                <span>
                  Informationen zur Produktsicherheit
                  <br />
                  (Hersteller/EU-Verantwortliche Person)
                </span>
                {manufacturerInfo}
              </p>
            </div>
          </div>
          <div className="flex w-full flex-col items-center justify-center gap-6 lg:w-1/2 lg:items-start lg:justify-center">
            {articles.map((article, index) =>
              article.content ? (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center lg:items-start lg:justify-center"
                >
                  <h1 className="font-GeistLight text-sm text-gray-400">{article.title}</h1>
                  <p className="font-Geist text-xl text-gray-900">{article.content}</p>
                </div>
              ) : null
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mb-[65px] mt-[65px] flex flex-col items-center justify-center">
      {product && (
        <div className="xl:max-w-1/2 relative mx-auto mb-[64px] flex h-auto w-full flex-col items-start justify-center md:max-w-[80%] lg:flex-row">
          <div className="pd__imgGallery w-full lg:w-1/2 xl:pr-8">
            <div className="flex flex-shrink flex-wrap items-center justify-center overflow-x-hidden">
              <Slider ref={sliderRef} {...settings} className="pd__slider">
                {filteredImages.map((slide, index) => (
                  <div
                    key={index}
                    onClick={() => setModalImage(getImageSrc(slide))}
                    className="flex items-center justify-center"
                  >
                    <img
                      src={getImageSrc(slide)}
                      alt={product.productName}
                      width={320}
                      height={320}
                      className="mx-auto object-cover"
                    />
                  </div>
                ))}
              </Slider>
            </div>
            {modalImage && (
              <div className="modal-background h-screen w-screen" onClick={() => setModalImage(null)}>
                <span onClick={() => setModalImage(null)}>
                  <svg width="24" height="24" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_696_2)">
                      <path d="M8 120L120 8.00002" stroke="#fff" strokeWidth="15" strokeLinecap="round" />
                      <path d="M8 8L120 120" stroke="#fff" strokeWidth="15" strokeLinecap="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_696_2">
                        <rect width="128" height="128" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                  <img
                  className="object-contain w-full h-full aspect-auto"
                  src={modalImage}
                  alt="Modal"
                />
              </div>
            )}
          </div>
          <div className="mx-auto flex w-full flex-col items-center justify-between lg:w-1/2 lg:items-start">
            <div className="">
              <p className="font-GeistLight text-center text-gray-500 lg:text-left">{product.brand}</p>
              <h1 className="font-Geist text-center text-3xl lg:text-left">{product.productName}</h1>
              <div className="card__details-single flex flex-col gap-4">
                <p className="mt-4 text-center text-base lg:text-left">{product.saleText}</p>
              </div>
            </div>
            <div className="flex flex-grow flex-col items-center justify-center lg:items-start lg:justify-start">
              <h3 className="my-6 flex text-center text-xl lg:text-left">
                <span className="font-bold">
                  {product.price?.toString()
                    ? `${formatPrice(product.price.toString())}€`
                    : "Preis nicht verfügbar"}
                </span>
                *
              </h3>
              <div className="flex flex-col items-center justify-center gap-2 lg:items-start">
                <span className="flex items-center justify-start gap-4">
                  <span className="flex items-center justify-center rounded-lg bg-blue-500/20 px-2 py-1">
                    Lieferung: 2 - 3 Werktage
                  </span>
                </span>
              </div>
            </div>
            <div className="mt-12 flex gap-8">
              {loggedInUser ? (
                <button
                  className="flex items-center justify-center gap-2 rounded-lg border-[1px] border-gray-900 bg-gray-200 px-4 py-2"
                  onClick={() => addToDatabaseCart({ id: product.id, quantity: 1, product })}
                >
                  Zum Warenkorb hinzufügen
                </button>
              ) : (
                <button
                  className="flex items-center justify-center gap-2 rounded-lg border-[1px] border-gray-900 bg-gray-200 px-4 py-2"
                  onClick={() => addToCart({ id: product.id, quantity: 1, product })}
                >
                  Zum Warenkorb hinzufügen
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {renderArticles()}
    </div>
  );
};

export default ProductDetail;
