import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
  WidthIcon,
} from "@radix-ui/react-icons";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { formatPrice } from "../../../common/components/layouts/defaultLayout/DefaultLayout";
import { Product } from "../../../utils/types";
import NavDropdown from "../dropdown/NavDropdown";
import "./FilteredWatch.css";

const optionsFamily = [
  { value: "Halsschmuck", label: "Halsschmuck" },
  { value: "Ohrschmuck", label: "Ohrschmuck" },
  { value: "Ringe", label: "Ringe" },
  { value: "Anhänger", label: "Anhänger" },
  { value: "Armschmuck", label: "Armschmuck" },
  { value: "Tantal", label: "Tantal" },
];

const optionsKind = [
  { value: "DIAMANTSCHMUCK", label: "Diamantschmuck" },
  { value: "GOLDSCHMUCK", label: "Goldschmuck" },
  { value: "SILBERSCHMUCK", label: "Silberschmuck" },
  { value: "TROLLBEADS", label: "Trollbeads" },
  { value: "TRAURINGE", label: "Trauringe" },
];

const optionsSort = [
  { value: "ASC", label: "Preis aufsteigend" },
  { value: "DESC", label: "Preis absteigend" },
];

const getImageSrc = (mainImage?: string | Text | null): string => {
  if (!mainImage) return "";

  const image = typeof mainImage === "string" ? mainImage : mainImage.textContent || "";

  if (image.startsWith("/images")) {
    return `https://arcview.nodiatech.de${image}`;
  }

  return `data:image/jpeg;base64,${image}`;
};


const RepeatedDiv: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center space-y-3 pb-4 pl-8 pr-8 pt-8 shadow-xl">
      <div className="h-[225px] w-full rounded-xl bg-gray-300" />
      <div className="flex flex-col items-center justify-center space-y-2">
        <div className="h-2 w-[150px] bg-gray-300" />
        <div className="h-5 w-[220px] bg-gray-300" />
        <div className="h-5 w-[250px] bg-gray-300" />
        <div className="h-2 w-[100px] bg-gray-300" />
      </div>
      <div className="flex flex-col items-center justify-center gap-1 pt-12">
        <div className="h-1 w-[100px] bg-gray-300"></div>
        <div className="h-5 w-[100px] bg-gray-300"></div>
      </div>
    </div>
  );
};

const FilteredPaginatedProductGridJewelry = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [shouldSearch, setShouldSearch] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const initialPage = parseInt(searchParams.get("page") || "1");

  const page = useMemo(() => {
      return parseInt(new URLSearchParams(location.search).get("page") || "1");
    }, [location.search]);
  const [brandOptions, setBrandOptions] = useState<{ value: string; label: string }[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedKind, setSelectedKind] = useState<string | null>(
    searchParams.get("productType") || localStorage.getItem("productType"),
  );
  const [selectedGroup, setSelectedGroup] = useState<string | null>(
    searchParams.get("productGroup") || localStorage.getItem("productGroup"),
  );
  const [selectedBrand, setSelectedBrand] = useState<string | null>(
    searchParams.get("brand") || localStorage.getItem("brand"),
  );
  const [selectedSort, setSelectedSort] = useState<string | null>(
    searchParams.get("sortBy") || localStorage.getItem("selectedSort"),
  );
  const [selectedProductGroup, setSelectedProductGroup] = useState<
    string | null
  >(
    searchParams.get("productGroup") ||
      localStorage.getItem("selectedProductGroup"),
  );
  const [minPrice, setMinPrice] = useState<number | null>(
    parseFloat(searchParams.get("minPrice") || "") || null,
  );
  const [maxPrice, setMaxPrice] = useState<number | null>(
    parseFloat(searchParams.get("maxPrice") || "") || null,
  );
  const [products, setProducts] = useState<Product[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectBrandChange = (
    selectedOption: { value: string; label: string } | null,
  ) => {
    const newBrand = selectedOption ? selectedOption.value : null;
    setSelectedBrand(newBrand);
    localStorage.setItem("selectedBrand", newBrand || "");
    const encodedBrand = selectedOption
      ? encodeURIComponent(selectedOption.value)
      : "";
    updateSearchParams("brand", encodedBrand);
  };

  const handleSelectGearChange = (
    selectedOption: { value: string; label: string } | null,
  ) => {
    const newClockwork = selectedOption ? selectedOption.value : null;
    setSelectedKind(newClockwork);
    localStorage.setItem("optionsKind", newClockwork || "");
    updateSearchParams(
      "productType",
      newClockwork ? encodeURIComponent(newClockwork) : "",
    );
  };
  const [selectedButton, setSelectedButton] = useState("Unisex");
  const handleSelectChange = (value: string) => {
    setSelectedButton(value);
    localStorage.setItem("selectedProductGroup", value);
    updateSearchParams("productGroup", value);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSelectSortChange = (
    selectedOption: { value: string; label: string } | null,
  ) => {
    const newSort = selectedOption ? selectedOption.value : null;
    setSelectedSort(newSort);
    localStorage.setItem("selectedSort", newSort || "");
    updateSearchParams("sortBy", "price");
    updateSearchParams(
      "sortDirection",
      newSort ? encodeURIComponent(newSort) : "",
    );
  };

  const handleSelectGroupChange = (
    selectedOption: { value: string; label: string } | null,
  ) => {
    const newClockwork = selectedOption ? selectedOption.value : null;
    setSelectedKind(newClockwork);
    localStorage.setItem("optionsKind", newClockwork || "");
    updateSearchParams(
      "productType",
      newClockwork ? encodeURIComponent(newClockwork) : "",
    );
  };

  const handleMinPriceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    setMinPrice(isNaN(value) ? null : value);
  };

  const handleMaxPriceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    setMaxPrice(isNaN(value) ? null : value);
  };

  const applyPriceFilter = () => {
    if (minPrice !== null) updateSearchParams("minPrice", minPrice.toString());
    else updateSearchParams("minPrice", "");
    if (maxPrice !== null) updateSearchParams("maxPrice", maxPrice.toString());
    else updateSearchParams("maxPrice", "");
  };

  const handlePageSelectInc = () => {
    const newPage = page < totalPages ? page + 1 : page;
    updateSearchParams("page", newPage.toString());
    scrollToTop();
  };

  const handlePageSelectDec = () => {
    const newPage = page > 1 ? page - 1 : 1;
    updateSearchParams("page", newPage.toString());
    scrollToTop();
  };

  const handlePageSelectFirst = () => {
    updateSearchParams("page", "1");
    scrollToTop();
  };

  const handlePageSelectLast = () => {
    updateSearchParams("page", totalPages.toString());
    scrollToTop();
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, page - 2);
    const endPage = Math.min(totalPages, page + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`pagination-button flex h-8 items-center justify-center border border-gray-300 px-3 leading-tight text-gray-500 hover:bg-gray-300 hover:text-gray-700 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
            i === page ? "bg-gray-300" : ""
          }`}
          onClick={() => updateSearchParams("page", i.toString())}
        >
          {i}
        </button>,
      );
    }

    return pageNumbers;
  };

  const resetFilters = () => {
    setSelectedBrand(null);
    setSelectedSort(null);
    setSelectedProductGroup(null);
    setMinPrice(null);
    setMaxPrice(null);
    setSearchTerm("");

    localStorage.removeItem("selectedBrand");
    localStorage.removeItem("selectedClockwork");
    localStorage.removeItem("selectedSort");
    localStorage.removeItem("selectedProductGroup");
    localStorage.removeItem("selectedProductfamily");

    navigate("/SCHMUCK?page=1", { replace: true });
  };

  const updateSearchParams = (key: string, value: string) => {
    const searchParams = new URLSearchParams(location.search);
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
    navigate(`/SCHMUCK?${searchParams.toString()}`);
  };

  const fetchProducts = useCallback(async () => {
    const currentPage = parseInt(new URLSearchParams(location.search).get("page") || "1");
    let url = `/api/aeb/products?productLine=SCHMUCK&page=${currentPage}`;
    if (selectedBrand) url += `&brand=${selectedBrand}`;
    if (selectedGroup) url += `&productGroup=${selectedGroup}`;
    if (selectedKind) url += `&productType=${selectedKind}`;
    if (selectedSort) url += `&sortBy=price&sortDirection=${selectedSort}`;
    if (selectedProductGroup) url += `&productGroup=${selectedProductGroup}`;
    if (minPrice !== null) url += `&minPrice=${minPrice}`;
    if (maxPrice !== null) url += `&maxPrice=${maxPrice}`;
    try {
      setIsLoading(true);
      const response = await fetch(url);
      const data = await response.json();
      if (data.length === 0) {
        setProducts([]);
      } else {
        setProducts(data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Fehler beim Laden der Produkte:", error);
      setIsLoading(false);
    }
  }, [location.search, selectedBrand, selectedGroup, selectedKind, selectedSort, selectedProductGroup, minPrice, maxPrice]);

  const fetchProductPages = useCallback(async () => {
    let url = `/api/aeb/product-pages?productLine=SCHMUCK`;
    if (selectedBrand) url += `&brand=${selectedBrand}`;
    if (selectedKind) url += `&productType=${selectedKind}`;
    if (selectedGroup) url += `&productGroup=${selectedGroup}`;
    if (selectedProductGroup) url += `&productGroup=${selectedProductGroup}`;
    if (minPrice !== null) url += `&minPrice=${minPrice}`;
    if (maxPrice !== null) url += `&maxPrice=${maxPrice}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error("Fehler beim Laden der Seitenanzahl:", error);
    }
  }, [
    selectedBrand,
    selectedGroup,
    selectedKind,
    selectedProductGroup,
    minPrice,
    maxPrice,
  ]);

  const fetchSearchProducts = useCallback(
    async (term: string) => {
      const currentPage = parseInt(new URLSearchParams(location.search).get("page") || "1");
      let url = `/api/aeb/search?term=${term}&page=${page}&productLine=SCHMUCK`;
      try {
        setIsLoading(true);
        const response = await fetch(url);
        const data = await response.json();
        if (data.products.length === 0) {
          setProducts([]);
        } else {
          setProducts(data.products);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Fehler beim Laden der Produkte:", error);
        setIsLoading(false);
      }
    },
    [location.search, page],
  );

  const fetchSearchProductPages = useCallback(async (term: string) => {
    let url = `/api/aeb/search-pages?term=${term}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error("Fehler beim Laden der Seitenanzahl:", error);
    }
  }, []);

  useEffect(() => {
  const fetchBrands = async () => {
    try {
      const response = await fetch("/api/aeb/brands?productLine=SCHMUCK");
      const data = await response.json();

      if (Array.isArray(data.brands)) {
        const formatted = data.brands.map((brand: string) => ({
          value: brand,
          label: brand,
        }));
        setBrandOptions(formatted);
      }
    } catch (error) {
      console.error("Fehler beim Laden der Marken:", error);
    }
  };

  fetchBrands();
}, []);




  useEffect(() => {
  if (shouldSearch && searchTerm) {
    fetchSearchProducts(searchTerm);
    fetchSearchProductPages(searchTerm);
    setShouldSearch(false);
  } else if (!searchTerm) {
    fetchProducts();
    fetchProductPages();
  }
}, [location.search, shouldSearch]);

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openFilter = () => setIsFilterOpen(true);
  const closeFilter = () => setIsFilterOpen(false);

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = () => {
    setShouldSearch(true);
  };

  return (
    <>
      <div className="watchesShopPage-container">
        <div className="mx-auto flex w-full max-w-[1560px] flex-col items-center justify-between border-gray-300 py-2">
          <span className="flex xl:hidden">
            <button
              onClick={openFilter}
              className="flex items-center justify-center gap-2 rounded-xl border-[1px] border-gray-600 px-2 py-1 xl:text-lg"
            >
              <span>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 1.5C5 1.22386 4.77614 1 4.5 1C4.22386 1 4 1.22386 4 1.5L4 7C4 7.01671 4.00082 7.03323 4.00242 7.04952C2.86009 7.28022 2 8.28967 2 9.5C2 10.7103 2.86009 11.7198 4.00242 11.9505C4.00082 11.9668 4 11.9833 4 12V13.5C4 13.7761 4.22386 14 4.5 14C4.77614 14 5 13.7761 5 13.5V12C5 11.9833 4.99918 11.9668 4.99758 11.9505C6.1399 11.7198 7 10.7103 7 9.5C7 8.28967 6.1399 7.28022 4.99758 7.04952C4.99918 7.03323 5 7.01671 5 7L5 1.5ZM11 1.5C11 1.22386 10.7761 1 10.5 1C10.2239 1 10 1.22386 10 1.5V3C10 3.01671 10.0008 3.03323 10.0024 3.04952C8.8601 3.28022 8 4.28967 8 5.5C8 6.71033 8.8601 7.71978 10.0024 7.95048C10.0008 7.96677 10 7.98329 10 8V13.5C10 13.7761 10.2239 14 10.5 14C10.7761 14 11 13.7761 11 13.5V8C11 7.98329 10.9992 7.96677 10.9976 7.95048C12.1399 7.71978 13 6.71033 13 5.5C13 4.28967 12.1399 3.28022 10.9976 3.04952C10.9992 3.03323 11 3.01671 11 3V1.5ZM4.5 8C3.67157 8 3 8.67157 3 9.5C3 10.3284 3.67157 11 4.5 11C5.32843 11 6 10.3284 6 9.5C6 8.67157 5.32843 8 4.5 8ZM9 5.5C9 4.67157 9.67157 4 10.5 4C11.3284 4 12 4.67157 12 5.5C12 6.32843 11.3284 7 10.5 7C9.67157 7 9 6.32843 9 5.5Z"
                    fill="currentColor"
                    fillRule="evenodd"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </span>
              Filter
            </button>
          </span>
          <NavDropdown isFilterOpen={isFilterOpen} closeFilter={closeFilter}>
            <p className="text-gray-500">Produktdetails</p>
            <div className="max-w-screen flex flex-col items-start justify-center gap-4 pb-8">
              <Select
                options={brandOptions}
                placeholder="Marke"
                value={
                  selectedBrand
                    ? brandOptions.find((option) => option.value === selectedBrand)
                    : null
                }
                className="w-full"
                onChange={handleSelectBrandChange}
              />
              <Select
                options={optionsKind}
                placeholder="Schmuckart"
                value={
                  selectedKind
                    ? optionsKind.find(
                        (option) => option.value === selectedKind,
                      )
                    : null
                }
                className="w-full"
                onChange={handleSelectGearChange}
              />
              <Select
                options={optionsSort}
                placeholder="Sortierung"
                value={
                  selectedSort
                    ? optionsSort.find(
                        (option) => option.value === selectedSort,
                      )
                    : null
                }
                className="w-full"
                onChange={handleSelectSortChange}
              />
            </div>
            <p className="text-gray-500">Preis</p>
            <span className="flex h-full items-center justify-between gap-2">
              <input
                className="rounded-lg border-[1px] border-gray-300 px-2 py-1"
                type="number"
                placeholder="0"
                value={minPrice !== null ? minPrice : ""}
                onChange={handleMinPriceChange}
              />
              <WidthIcon />
              <input
                className="rounded-lg border-[1px] border-gray-300 px-2 py-1"
                type="number"
                placeholder="10000"
                value={maxPrice !== null ? maxPrice : ""}
                onChange={handleMaxPriceChange}
              />
            </span>
          </NavDropdown>
          <span className="hidden w-max items-center justify-start gap-4 lg:flex">
            <div className="flex w-max items-start justify-center gap-4">
              <Select
                options={brandOptions}
                placeholder="Marke"
                value={
                  selectedBrand
                    ? brandOptions.find(
                        (option) => option.value === selectedBrand,
                      )
                    : null
                }
                className="w-[30ch]"
                onChange={handleSelectBrandChange}
              />
              <Select
                options={optionsKind}
                placeholder="Schmuckart"
                value={
                  selectedKind
                    ? optionsKind.find(
                        (option) => option.value === selectedKind,
                      )
                    : null
                }
                className="w-[30ch]"
                onChange={handleSelectGearChange}
              />
              <Select
                options={optionsSort}
                placeholder="Sortierung"
                value={
                  selectedSort
                    ? optionsSort.find(
                        (option) => option.value === selectedSort,
                      )
                    : null
                }
                className="w-[20ch]"
                onChange={handleSelectSortChange}
              />
              <span className="flex h-full items-center justify-between gap-2">
                <input
                  className="rounded-lg border-[1px] border-gray-300 px-2 py-1"
                  type="number"
                  placeholder="0"
                  value={minPrice !== null ? minPrice : ""}
                  onChange={handleMinPriceChange}
                />
                <WidthIcon />
                <input
                  className="rounded-lg border-[1px] border-gray-300 px-2 py-1"
                  type="number"
                  placeholder="10000"
                  value={maxPrice !== null ? maxPrice : ""}
                  onChange={handleMaxPriceChange}
                />
              </span>
            </div>
            <button onClick={resetFilters}>Filter zurücksetzen</button>
          </span>
        </div>
        <section className="bg-gray-100">
          <div className="w-f1ll flex flex-col items-center justify-center">
            <div className="mt-8 flex items-center justify-center">
              <h1 className="text-xl xl:text-6xl">Unser Schmuck-Sortiment</h1>
            </div>
            <div className="w-full">
              <div className="mx-auto grid w-full max-w-[1560px]">
                <div className="pagination mt-2 flex w-full flex-col items-center justify-between gap-12 lg:flex-row">
                  <div className="flex w-full items-center justify-center gap-2 px-4 lg:px-0">
                    <div className="relative flex w-full items-center justify-center">
                      <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                        placeholder="Suche..."
                        className="w-full rounded-lg border-[1px] border-gray-300 px-4 py-2"
                      />
                    </div>
                    <button
                      onClick={handleSearchSubmit}
                      className="rounded-lg bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
                    >
                      Suchen
                    </button>
                  </div>
                  {totalPages === 0 ? (
                    <></>
                  ) : (
                    <div className="flex h-8 items-center -space-x-px text-sm">
                      <button
                        className="pagination-button ms-0 flex h-8 items-center justify-center rounded-s-lg border border-e-0 border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={handlePageSelectFirst}
                      >
                        <DoubleArrowLeftIcon />
                      </button>
                      <button
                        className="pagination-button ms-0 flex h-8 items-center justify-center border border-e-0 border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={handlePageSelectDec}
                      >
                        <ChevronLeftIcon />
                      </button>
                      {renderPageNumbers()}
                      <button
                        className="pagination-button ms-0 flex h-8 items-center justify-center border border-s-0 border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={handlePageSelectInc}
                      >
                        <ChevronRightIcon />
                      </button>
                      <button
                        className="pagination-button ms-0 flex h-8 items-center justify-center rounded-e-lg border border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        onClick={handlePageSelectLast}
                      >
                        <DoubleArrowRightIcon />
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {!isLoading ? (
                products.length > 0 ? (
                  <div className="mx-auto mt-5 grid w-full max-w-[1560px] grid-cols-2 gap-x-4 gap-y-4 md:grid-cols-4">
                    {Array.isArray(products) &&
                      products.map((product) => (
                        <Link
                          className="pc__detailLink w-full bg-white pb-4 pl-8 pr-8 pt-8 shadow-lg"
                          to={`/${product.productLine}/${product.articleId}`}
                          key={product.articleId}
                        >
                          <div
                            key={product.articleId}
                            className="flex h-full max-w-full flex-col items-center justify-between rounded-lg"
                          >
                            <div className="flex flex-col items-center justify-center">
                              {product.mainImage && (
                                <img
                                  width={300}
                                  height={300}
                                  src={getImageSrc(product.mainImage)}
                                  alt={product.productName}
                                  className="aspect-square"
                                />
                              )}
                              <div className="mt-4 flex flex-col">
                                <p className="text-center text-gray-500">
                                  {product.brand}
                                </p>
                                <h3 className="text-center text-lg">
                                  {product.productName}
                                </h3>
                                <p className="text-center text-gray-500">
                                  {product.articleId}
                                </p>
                              </div>
                            </div>
                            <p className="mt-8 border-t-[1px] border-gray-400 text-center text-lg font-bold dark:border-gray-700">
                              {formatPrice(product.price.toString())}€
                            </p>
                          </div>
                        </Link>
                      ))}
                  </div>
                ) : (
                  <div className="mx-auto mt-5 flex items-center justify-center">
                    <p className="text-lg text-gray-500">
                      Keine Produkte gefunden
                    </p>
                  </div>
                )
              ) : (
                <div className="mx-auto mt-5 grid w-full max-w-[1560px] grid-cols-2 gap-x-4 gap-y-4 md:grid-cols-4">
                  {Array.from({ length: 16 }).map((_, index) => (
                    <RepeatedDiv key={index} />
                  ))}
                </div>
              )}
              <div className="mx-auto grid w-full max-w-[1560px] py-4">
                <div className="pagination mt-2 flex w-full items-end justify-end gap-4">
                  <button
                    className="flex items-center justify-center rounded-lg border border-gray-300 bg-white p-2"
                    onClick={scrollToTop}
                  >
                    <ChevronUpIcon />
                  </button>
                  <div className="flex h-8 items-center -space-x-px text-sm">
                    <button
                      className="pagination-button ms-0 flex h-8 items-center justify-center rounded-s-lg border border-e-0 border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={handlePageSelectFirst}
                    >
                      <DoubleArrowLeftIcon />
                    </button>
                    <button
                      className="pagination-button ms-0 flex h-8 items-center justify-center border border-e-0 border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={handlePageSelectDec}
                    >
                      <ChevronLeftIcon />
                    </button>
                    {renderPageNumbers()}
                    <button
                      className="pagination-button ms-0 flex h-8 items-center justify-center border border-s-0 border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={handlePageSelectInc}
                    >
                      <ChevronRightIcon />
                    </button>
                    <button
                      className="pagination-button ms-0 flex h-8 items-center justify-center rounded-e-lg border border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={handlePageSelectLast}
                    >
                      <DoubleArrowRightIcon />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default FilteredPaginatedProductGridJewelry;
